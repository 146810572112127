









import { Component, Prop, Vue } from "vue-property-decorator";
import smoothReflow from "vue-smooth-reflow";

@Component({
  mixins: [smoothReflow],
})
export default class Collapse extends Vue {
  @Prop(String) name!: string;
  @Prop({ type: String, default: "" }) match!: string;
  show: boolean = this.name.toLowerCase() === this.match;

  toggle(): void {
    this.show = !this.show;
  }

  mounted() {
    this.$smoothReflow?.({
      transition: "height .25s",
    });
  }
}
